import './App.css';
import { BrowserRouter as Router,Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Feedback from './pages/Feedback';
import Questionnair from './pages/Questionnair';
import Home from './pages/Home';
import Report from './pages/Report';
import RestartSurvey from './pages/RestartSurvey';
import ThemeProvider from './theme'
import Header from './component/Header';
import Layout from './layout/Layout';
import ReportPreview from './pages/ReportPreview';
import PassSlug from './pages/PassSlug';

function App() {
  return (
    <div className="App">
       <Router>
        <Routes>

          {/* {component.map((u, i) =>
            checkCallback(authToken, appToken, authType, callback, ssoToken) ?
              <Route key={i} path={u.url} element={u.page} />
              :
              (window.location.assign(loginauthenticationUrl))
          )} */}
          {/* <Route path="/logout" element={<Logout />} /> */}

          <Route path="/" element={<Layout> <ThemeProvider><Home /></ThemeProvider></Layout>} />
          {/* <Route path="/home" element={<Layout> <ThemeProvider><Home /></ThemeProvider></Layout>} /> */}
          {/* <Route path="/mdm" element={<Layout showXpoLabel={false}><ThemeProvider><Home /></ThemeProvider></Layout>} /> */}
          <Route path="/:slug/feedback" element={<PassSlug name={"Feedback"} />} />
          <Route path="/:slug/questionnaire" element={<PassSlug name={"Questionnair"} />} />
          <Route path="/:slug/report" element={<PassSlug name={"Report"} />} />
          <Route path="/:slug/restart" element={<PassSlug name={"RestartSurvey"} />} />
          <Route path='/:slug/report-preview' element={<PassSlug name={"ReportPreview"} />} />
          <Route path="/:slug" element={<Navigate to="questionnaire" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
       </Router>
    </div>
  );
}

export default App;
