import React, {useEffect,useState} from "react";
import { Box, Button  } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Header from "../component/Header";
import Footer from "../component/Footer";
import Typography from '@mui/material/Typography';
import CustomizedButtons from "../styledComponents/CustomizedButtons";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import homeBanner from '../assets/images/homeBanner.jpg';
import ApiService from "../utils/ApiService";
import Background from "../component/Background";
import { useMediaQuery } from 'react-responsive';

const Home = () => {

    // const slug = "mdm-survey";

    const [survey, setSurvey] = useState([]);
    // const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    // const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1366px)' });
    // const isTabletLandscape = useMediaQuery({ query: '(min-width: 1024px) and (max-width: 1366px)' });
    // const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
    // const isTabletPortrait = useMediaQuery({ query:'(min-width: 577px) and (max-width: 768px) and (orientation: portrait)'});
    const isMobilePortrait = useMediaQuery({ query:'(max-device-width: 767px) and (orientation: portrait)'});
    const isMobileLandscape = useMediaQuery({ query:'(max-device-width: 767px) and (orientation: landscape)'});
    const isTabletPortrait = useMediaQuery({ query:'(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)'});
    const isTabletLandscape = useMediaQuery({ query:'(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)'});
    const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
    // const isDesktopPortrait = useMediaQuery({ query:'(min-width: 1025px) and (orientation: portrait)'});
    // const isDesktopLandscape = useMediaQuery({ query:'(min-width: 1025px) and (orientation: landscape'});

    // const [backgroundImage, setBackgroundImage] = useState('/media/tabletFull.svg');
    const [backgroundImage, setBackgroundImage] = useState('/media/desktop.jpg');
    useEffect(() => {
        setBackgroundImage(
            isMobilePortrait ? '/media/Iphone.jpg' : 
            isMobileLandscape ? '/media/Ipad.jpg' : 
            isTabletPortrait ? '/media/Ipad.jpg' :
            isTabletLandscape ? '/media/Ipad lanndscape.jpg' :
            isDesktop ? '/media/desktop.jpg' :
            // isDesktopLandscape ? '/media/website.jpg' :
               '/media/desktop.jpg' );
               console.log("Old Fashoned", {background: isMobilePortrait ? '/media/Iphone.jpg' : 
               isMobileLandscape ? '/media/Ipad.jpg' : 
               isTabletPortrait ? '/media/Ipad.jpg' :
               isTabletLandscape ? '/media/Ipad lanndscape.jpg' :
               isDesktop ? '/media/desktop.jpg' :
               // isDesktopLandscape ? '/media/website.jpg' :
                  '/media/desktop.jpg', isMobilePortrait,isMobileLandscape,isTabletPortrait,isTabletLandscape,isDesktop})
    }, [isMobilePortrait,isMobileLandscape,isTabletPortrait,isTabletLandscape,isDesktop])

    const [backgroundWebP, setBackgroundWebP] = useState('/media/desktop.webp');
    useEffect(() => {
        setBackgroundWebP(
            isMobilePortrait ? '/media/Iphone.webp' : 
            isMobileLandscape ? '/media/Ipad.webp' : 
            isTabletPortrait ? '/media/Ipad.webp' :
            isTabletLandscape ? '/media/Ipad lanndscape.webp' :
            isDesktop ? '/media/desktop.webp' :
            // isDesktopLandscape ? '/media/website.webp' :
               '/media/desktop.webp' );
               console.log("Old Fashoned", {background: isMobilePortrait ? '/media/Iphone.webp' : 
               isMobileLandscape ? '/media/Ipad.webp' : 
               isTabletPortrait ? '/media/Ipad.webp' :
               isTabletLandscape ? '/media/Ipad lanndscape.webp' :
               isDesktop ? '/media/desktop.webp' :
               // isDesktopLandscape ? '/media/website.webp' :
                  '/media/desktop.webp', isMobilePortrait,isMobileLandscape,isTabletPortrait,isTabletLandscape,isDesktop})
    }, [isMobilePortrait,isMobileLandscape,isTabletPortrait,isTabletLandscape,isDesktop])
    const navigate = useNavigate();

    useEffect(() => {
        ApiService.get('surveyList')
        .then((res) => {
            let data = res.data.data;
            console.log({res,data});
            // navigate(`/${slug}/questionnaire`,{state: {questions: data}});
            if(data){
                setSurvey(data.filter(v => v.activeStatus == 1));
            }
        }).catch((exc) => {
            console.log({exc})
        })
    }, []);

    const goToQuestionnair = (slug) => {
        // ApiService.get('fetchSurvey', {}, slug)
        // .then((res) => {
        //     let data = res.data.data?.questionGroup?.reduce((prev,curr) => {
        //         prev = [...prev,...curr.questions];
        //         return prev;
        //     }, []);
        //     console.log({res,data});
        //     navigate(`/${slug}/questionnaire`,{state: {questions: data}});
        // }).catch((exc) => {
        //     console.log({exc})
        // })
        navigate(`/${slug}/questionnaire`);
    }

    const textStyle = {
        backgroundImage: `url(${backgroundWebP}), url(${backgroundImage})`,
        backgroundSize:'cover',
        backgroundPosition:'right bottom',
      
        // height:'calc(100vh - 30px)',
        // minHeight: "100vh",
         minHeight: "calc(100vh - 34px)",
       backgroundattachment: 'fixed',
    //    height:'100vh',
        width:'100%',
    // backgroundSize: "cover",
    //     backgroundPosition: "center center",
    //     // minHeight: "100vh",
    //     minHeight: "calc(100vh - 35px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // alignItems: "center",
        textAlign:"left",
     };

    return(
    <Box sx={{}} className="">
        {/* <Header /> */}
        {/* <Box style={textStyle}>
            <Background />
        </Box> */}
        <Box style={textStyle}>
        {/* <Box sx={{}}> */}
        {/* <Box sx={{position: 'relative', top: '94px', height: '60.4vh',display:'flex'}}></Box> */}
            {/* <Box sx={{position: 'relative', top: '94px', height: '60.4vh',display:'flex'}}> */}
                <Box sx={{color: "#FFF",maxWidth:'70rem',px:7}}>
                    {/* <Typography sx={{fontSize: 'clamp(1.875rem, 0rem + 4vw, 3.375rem)',textWrap:'balance',maxWidth:'60rem'}}> */}
                    <Typography variant="h1" sx={{textWrap:'balance'}}>
                        MDM Modernization Maturity Model
                    </Typography>
                    <Typography variant="body2" sx={{color:"rgba(252, 163, 17, 1)"}} pb={3} pt={2}>
                        MDM Maturity Assessment
                    </Typography>
                    {/* {survey.map((s,i) => {
                        return(<Box sx={{display: 'flex', py: 2}}>
                                <CustomizedButtons onClick={() => goToQuestionnair(s.slug)} label={s.surveyTitle} sx={{color: '#ffffff', 
                                '&:hover': {
                                    // backgroundColor: '#FFFFFF', // Prevent hover color change
                                    borderColor:'#ffffff'
                                  },background: 'transparent',borderColor:'#ffffff'}} />
                            </Box>)
                    })} */}
                </Box>
            {/* </Box> */}
        {/* </Box> */}
        
        </Box>
        <Footer />
    </Box>);
}

export default Home;