import React, { useState, useRef, useEffect } from "react";
import { Grid, Box, Tabs, Tab, Button, IconButton, Typography } from "@mui/material";
import Item from "../styledComponents/Item";
import { useNavigate } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReactToPDF from 'react-to-pdf';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import SpiderChart from "../component/SpiderChart";
import PolarAreaChart from "../component/PolarAreaChart";
import ApiService from "../utils/ApiService";
import TableComponent from "../features/TableComponent/TableComponent";
import reportTableColumns from "../dummy/reportTableColumns";
import reportTableData from "../dummy/reportTableData.json";
import reportTableData2 from "../dummy/reportTableData copy.json";
import Header from "../component/Header";
import Footer from "../component/Footer";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useLocation } from 'react-router-dom';
import AlertDialog from "../component/AlertDialog";
import { useMediaQuery, useTheme } from '@mui/material';


const Report = ({ slug }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [mailUrl, setMailUrl] = useState(location.state?.mailUrl);
    console.log('a1', mailUrl)
    const ref = useRef();

    const headerHeight = useRef();
    const footerHeight = useRef();
    const [bodyHeight, setBodyHeight] = useState('90vh');

    const [contentHeight, setContentHeight] = useState('60vh');
    const landscapeQuery = useMediaQuery('(orientation: landscape)');
    const screenWidthQuery = useMediaQuery('(min-width: 769px)');
    const isLandscapeWide = landscapeQuery && screenWidthQuery;
    const pageHeading = useRef();
    const pageFooter = useRef();
    const [tabIndex, setTabIndex] = React.useState("1");
    const [chartData, setChartData] = React.useState([]);
    const [surveyAnswers, setSurveyAnswers] = useState(location.state?.report);
    const [reportTableJson, setReportTableJson] = useState(slug == 'mdm-survey' ? reportTableData2 : reportTableData);
    const [tabContentHeight, setTabContentHeight] = useState('60vh');
    const [open, setOpen] = React.useState(false);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const [largeQuery, setIsLarge] = useState(false);
    const theme = useTheme();
    // const largeQuery = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        if (!surveyAnswers) {
            goToHome();
        }
        if (landscapeQuery === false || screenWidthQuery) {
            setContentHeight('calc(100vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px - ' + pageHeading?.current?.clientHeight + 'px - ' + pageFooter?.current?.clientHeight + 'px - 60px)');
        } else {
            setContentHeight('unset')
        }
        setTabContentHeight('calc(100vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px - ' + pageHeading?.current?.clientHeight + 'px - ' + pageFooter?.current?.clientHeight + 'px - 108px)');
        setBodyHeight('calc(105vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px)')
        ApiService.get('fetchResult', { sessionId: surveyAnswers?.session, slug: slug })
            .then((res) => {
                setChartData(res.data.map(v => Math.floor(v.average)));
                console.log({ reportTableData, res })
            })
            .catch((exc) => {
                console.log({ exc })
            })
        // document.title = "HCLTech Survey";
    }, [landscapeQuery, screenWidthQuery])

    const index = ['', 'Nascent', 'Focusing', 'Emerging', 'Establishing', 'Leading'];

    useEffect(() => {
        let json = (slug == 'mdm-survey' ? reportTableData2 : reportTableData);
        setReportTableJson(json.map((v, i) => {
            return { ...v, background: index[chartData[i]] }
        }))
    }, [chartData])

    const goToFeedBack = () => {
        if (tabIndex == '1') {
            setTabIndex("2")
        } else {
            // navigate('/feedback', {state: {report: surveyAnswers, chartData: chartData}});
            navigate(`/${slug}/report-preview`, { state: { report: surveyAnswers, chartData: chartData, mailUrl: mailUrl } });
        }
    }

    const handleScheduleConsultant = () => {
        window.open(mailUrl);
        console.log('a1', mailUrl)
    };


    const restartDialogOpen = () => {
        setOpen(true);
    };

    const goToHome = () => {
        navigate(`/${slug}/questionnaire`);
    }

    const generatePDF = async () => {
        const element = ref.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        pdf.addImage(data, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        pdf.save('report.pdf');
    };

    return (
        <Box sx={{ height: '100vh', width: '100%', position: 'relative', background: 'rgba(100, 122, 163, 0.2)' }}>
            <Header headerHeight={headerHeight} />
            <Box sx={{
                position: 'absolute', top: headerHeight?.current?.clientHeight ?? '6rem', height: isLandscapeWide ? bodyHeight : 'unset', padding: '8px 2rem', width: '100%', background: 'rgba(100, 122, 163, 0.2)'
            }}>
                <Box ref={pageHeading} sx={{ textAlign: 'left', width: '100%' }}>
                    <Typography variant="subtitle1">
                        Benchmark Report
                    </Typography>
                    <Typography variant="subtitle2" sx={{ paddingBottom: '1rem' }}>
                        Your valuable input has been carefully analyzed. Here is a summary of the survey results:
                    </Typography>
                </Box>
                <Box sx={{ background: '#FFFFFF', width: '100%', position: 'relative' }}>
                    <TabContext value={tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="Tabs">
                                {/* icon={<StarBorderIcon />} iconPosition="start" */}
                                <Tab label="Where are you" value="1" />
                                <Tab label="What you need to do" value="2" />
                            </TabList>
                        </Box>
                        <Box sx={{ maxHeight: contentHeight ?? '60vh', overflow: 'auto' }}>
                            <TabPanel value="1">
                                <Box id="chartContainer" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: tabContentHeight, minWidth: '700px' }}>
                                    <Box ref={ref} id="polarChart" sx={largeQuery ? { width: '100%' } : {}}>
                                        <PolarAreaChart chartData={chartData} slug={slug} legendPosition={largeQuery} />
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2" id="hideScrollBar">
                                <Box id="table" sx={{ minHeight: tabContentHeight, minWidth: '700px' }}>
                                    <TableComponent columns={reportTableColumns} data={reportTableJson} />
                                </Box>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>

                <Box sx={{ width: '100%' }} ref={pageFooter}>

                    <Box sx={{ justifyContent: 'space-between', padding: isLandscapeWide ? '1rem 1rem' : '3rem 0rem' }}>
                        {/* <CustomizedButtons type="submit" label={"Next"} /> */}
                        {/* <IconButton aria-label="Home" onClick={goToHome}>
                            <RefreshIcon />
                        </IconButton> */}
                        {/* <Button variant="contained" color="primary" onClick={generatePDF}>
                            Generate PDF
                        </Button> */}
                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            <Box>
                                <Grid container spacing={2} >
                                    <Grid item xs={6}>
                                        <Box>
                                            <Button variant="outlined" onClick={restartDialogOpen} >Restart Survey</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black' }} onClick={handleScheduleConsultant}>
                                                Schedule Consultation
                                            </Button>
                                            <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black',ml:2 }} onClick={goToFeedBack}>
                                                {tabIndex == 1 ? 'Next' : 'Send Report'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box sx={{ display: { xs: "block", md: "none" } }}>
                            <Box>
                                <Grid container rowSpacing={1}>
                                    <Grid item xs={12}>
                                        <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black', width: '100%' }} onClick={handleScheduleConsultant}>
                                            Schedule Consultation
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Box>
                                    <Button variant="outlined" onClick={restartDialogOpen} sx={{padding:{xs:'5px 5px'}}}>Restart Survey</Button>
                                </Box>
                                <Box >
                                    <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black' }} onClick={goToFeedBack}>
                                        {tabIndex == 1 ? 'Next' : 'Send Report'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>


                        {/* <Box sx={{width:'100%'}}>
                            <Box>
                                <Grid container spacing={2} >
                                    <Grid item xs={8} sm={5} md={5} lg={9}>
                                        <Box>
                                            <Button variant="outlined" onClick={restartDialogOpen} >Restart Survey</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={4} lg={2}>
                                        <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                                            <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black'}} onClick={handleScheduleConsultant}>
                                                Schedule Counsultant
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={3} lg={1}>
                                        <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                                            <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black' }} onClick={goToFeedBack}>
                                                {tabIndex == 1 ? 'Next' : 'Send Report'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{display: {xs: "block", md: "none"}}}>
                            <Grid container spacing={2} >
                                    <Grid item xs={12} sm={5} md={5} lg={9}>
                                    <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                                            <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black'}} onClick={handleScheduleConsultant}>
                                                Schedule Counsultant
                                            </Button>
                                        </Box>
                                    
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={4} lg={2}>
                                        <Box>
                                            <Button variant="outlined" onClick={restartDialogOpen} >Restart Survey</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={3} lg={1}>
                                        <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                                            <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black' }} onClick={goToFeedBack}>
                                                {tabIndex == 1 ? 'Next' : 'Send Report'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            
                        </Box> */}
                    </Box>
                </Box>

                <AlertDialog open={open} setOpen={setOpen} goToHome={goToHome} />

            </Box>
            <Footer footerHeight={footerHeight} />
        </Box>
    );
}

export default Report;