import React, { useState, useRef, useEffect } from "react";
import { Grid, Box, Typography, Button, IconButton, FormControl, FormLabel, Link } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import Header from "../component/Header";
import Footer from "../component/Footer";
import CustomizedButtons from "../styledComponents/CustomizedButtons";
// import Typography from '@mui/material/Typography';
import FormTextField from "../component/TextField";
import FormSelectField from "../component/SelectBox";
import CheckboxField from "../component/ChecboxField";
import ApiService from "../utils/ApiService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import FormRadioGroup from "../component/RadioGroup";
import AlertDialog from "../component/AlertDialog";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Feedback = ({ slug }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [surveyAnswers, setSurveyAnswers] = useState(location.state?.report);
    const [mailUrl, setMailUrl] = useState(location.state?.mailUrl);
    console.log("a1", mailUrl)
    // const [chartData, setChartData] = useState(location.state?.chartData);
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [domain, setDomain] = useState("");
    const [organization, setOrganization] = useState("");
    const [sizeOfOrganization, setSizeOfOrganization] = useState("");
    const [productTime, setProductLong] = useState("");
    const [agree, setAgree] = useState(false);

    const headerHeight = useRef();
    const footerHeight = useRef();
    const [bodyHeight, setBodyHeight] = useState('90vh');
    const [contentHeight, setContentHeight] = useState('60vh');
    const pageHeading = useRef();
    const pageFooter = useRef();
    const [contentTop, setContentTop] = useState('6rem');
    const [needError, setNeedError] = useState(false);

    const [surveyQuestions, setSurveyQuestions] = useState(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (!surveyAnswers) {
            goToHome();
        }
        setContentTop(headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight);
        setContentHeight('calc(100vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px - ' + pageHeading?.current?.clientHeight + 'px - ' + pageFooter?.current?.clientHeight + 'px)');
        setBodyHeight('calc(100vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px)')
        // document.title = "HCLTech Survey";
    }, [])

    const goToRestartSurvey = (event) => {
        event.preventDefault();
        // console.log({name,email,organization, needError});
        if (!needError) {
            ApiService.post('submitDetails', { name: name, email: email, domain: domain, role: role, organizationName: organization, sizeOfOrganization: sizeOfOrganization, duration: productTime, sessionId: surveyAnswers?.session, acceptedCommunication: surveyQuestions ? 1 : 0, acceptedTermAndCondition: agree ? 1 : 0 })
                .then((res) => {
                    console.log({ res });
                    console.log({ mailUrl });
                    // navigate('/restart', {state: {restart: res.data}});
                    // navigate('/report-preview', {state: {restart: res.data, report: surveyAnswers, chartData: chartData}});
                    navigate(`/${slug}/report`, { state: { report: surveyAnswers, mailUrl: mailUrl } });
                })
                .catch((exc) => {
                    console.log({ exc });
                })
        }
        // navigate('/report-preview', {state: {report: surveyAnswers, chartData: chartData}});
    }

    const restartDialogOpen = () => {
        setOpen(true);
    };

    const goToHome = () => {
        navigate(`/${slug}/questionnaire`);
    }

    const goToReport = () => {
        window.history.back();
    }

    const getLabel = (
        <span style={{display:'block'}}>
            I have read
            <Link href="https://mastechinfotrellis.com/privacy-policy" target="_blank"> Mastech Infotrellis’s Privacy Policy </Link>
            and agree to the
            <Link href="https://mastechinfotrellis.com/term-of-use" target="_blank"> terms and conditions</Link>
            *
        </span>
    );

    return (<Box sx={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="background-body">
        <Header headerHeight={headerHeight} />
        <Box sx={{ position: 'absolute', top: 'calc(' + contentTop + ' - 1rem)' ?? '10rem', left: '2rem', zIndex: 1 }}>
            <IconButton onClick={goToReport} aria-label="Back">
                <ArrowBackIosIcon fontSize="small" /> <Typography variant="caption">Back</Typography>
            </IconButton>
        </Box>
        <Box sx={{ position: 'absolute', top: contentTop ?? '10rem', height: bodyHeight, padding: '0 2rem', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'left' }}>
            <Box ref={pageHeading} sx={{ width: '100%' }}>
                <Typography variant="body1" sx={{ paddingBottom: '1rem',paddingTop:'1rem' }}>
                    Please fill in your details to submit your response.
                </Typography>
            </Box>
            <Box>
                <form onSubmit={goToRestartSurvey}>
                    <Grid container spacing={2} sx={{ maxWidth: '35rem', maxHeight: contentHeight ?? '50vh', overflow: 'auto', scrollbarWidth: 'auto !important' }}>
                        <Grid item xs={12}>
                            <FormTextField label="Name" variant="outlined" sx={{ background: 'rgba(255, 255, 255, 1)', width: '100%' }} required={true} value={name} setValue={setName} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField label="Work Email Id" variant="outlined" sx={{ background: 'rgba(255, 255, 255, 1)', width: '100%' }} type="email" required={true} value={email} setValue={setEmail} setNeedError={setNeedError} />
                        </Grid>
                        {/* <Grid item xs={12}>
                                    <FormTextField label="Designation" variant="outlined" sx={{background: 'rgba(255, 255, 255, 1)', width: '100%'}}  required={false} value={role} setValue={setRole} />
                                </Grid> */}
                        <Grid item xs={12}>
                            <FormTextField label="Organization" variant="outlined" sx={{ background: 'rgba(255, 255, 255, 1)', width: '100%' }} required={false} value={organization} setValue={setOrganization} />
                        </Grid>
                        {/* <Grid item xs={12}>
                                    <FormSelectField label="How long have you been on the product journey?" value={productTime} setValue={setProductLong} controls={[{label: '<1   year', value: 0 },{label: '1-3 years', value: 1 },{label: '3-5 years', value: 2 },{label: '>5 years', value: 3 }]} sx={{background: 'rgba(255, 255, 255, 1)', width: '100%'}}  />
                                </Grid> */}
                        <Grid item xs={12}>
                            <CheckboxField label="Would you like to receive case studies, whitepapers, newsletters, announcements, and other 
relevant updates about products and services, events and webinars from Mastech Infotrellis? 
You may unsubscribe from these communications at any time." value={surveyQuestions} required={false} setValue={setSurveyQuestions} />
                        </Grid>
                        <Grid item xs={12} sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none'}}} >
                            <CheckboxField label={getLabel} value={agree} required={true} setValue={setAgree} />
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%' }} ref={pageFooter}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 0' }}>
                            <Button variant="outlined" onClick={restartDialogOpen} >Restart Survey</Button>
                            <CustomizedButtons sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black', border: 'none' }} type="submit" label={"Submit"} />
                        </Box>
                        <AlertDialog open={open} setOpen={setOpen} goToHome={goToHome} />
                    </Box>
                </form>
            </Box>
        </Box>
        <Footer footerHeight={footerHeight} />
    </Box>);
}

export default Feedback;