import Report from './Report'; 
import Feedback from './Feedback';
import ReportPreview from './ReportPreview';
import RestartSurvey from './RestartSurvey';
import Questionnair from './Questionnair';
import ThemeProvider from '../theme';
import { useNavigate, Link, useParams } from "react-router-dom";

const PassSlug = ({ name }) => {
    
    const { slug } = useParams();
    console.log({slug,name});

    const renderElement = () => {
        console.log({name})
        switch(name){
            case 'Report' : return (<Report slug={slug} />)
            case 'Feedback' : return (<Feedback slug={slug} />)
            case 'ReportPreview' : return (<ReportPreview slug={slug} />)
            case 'RestartSurvey' : return (<RestartSurvey slug={slug} />)
            case 'Questionnair' : return (<Questionnair slug={slug} />)
        }
    }   

  return (
    <ThemeProvider>
       
      {/* <Report slug={slug} /> Pass the slug as a prop to Report */}
      {slug && renderElement()}
    </ThemeProvider>
  );
};

export default PassSlug;