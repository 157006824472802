import React from "react";
import {Box} from '@mui/material';

const ShowHighlighted = ({data,highlighted,sx = {}}) => {
    return (<Box sx={highlighted ? {background: highlighted, ...sx}: {...sx}}>
        {data}
    </Box>)
}

const reportTableColumns = [
    { id: 'Dimensions', label: 'Dimensions / Levels', minWidth: 70, template: (row) => {return <ShowHighlighted data={row.Dimensions} highlighted={row.highlighted} />}},
    { id: 'Nascent', label: 'Nascent', minWidth: 100, template: (row) => {return <ShowHighlighted data={row.Nascent} highlighted={row.highlighted} sx={{background: row.background == 'Nascent' ? 'rgba(190, 235, 180, 1)' : 'transparent'}} />}},
    { id: 'Focusing', label: 'Focusing', minWidth: 100, template: (row) => {return <ShowHighlighted data={row.Focusing} highlighted={row.highlighted}  sx={{background: row.background == 'Focusing'  ? 'rgba(190, 235, 180, 1)' :  'transparent'}}  />}},
    { id: 'Emerging', label: 'Emerging', minWidth: 100, template: (row) => {return <ShowHighlighted data={row.Emerging} highlighted={row.highlighted}  sx={{background: row.background == 'Emerging'  ? 'rgba(190, 235, 180, 1)' :  'transparent'}}  />}},
    { id: 'Establishing', label: 'Establishing', minWidth: 100, template: (row) => {return <ShowHighlighted data={row.Establishing} highlighted={row.highlighted}  sx={{background: row.background == 'Establishing'  ? 'rgba(190, 235, 180, 1)' :  'transparent'}}  />}},
    { id: 'Leading', label: 'Leading', minWidth: 100, template: (row) => {return <ShowHighlighted data={row.Leading} highlighted={row.highlighted}  sx={{background: row.background == 'Leading'  ? 'rgba(190, 235, 180, 1)' :  'transparent'}}  />}},
  ];

  export default reportTableColumns;