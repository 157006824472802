import React from "react";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './TableComponent.css';


const TableComponent = ({columns, data}) => {

    return (<>
         <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column, index) => {
                            let sx = {backgroundColor:'rgba(240, 242, 248, 1)'};
                            if(column.frozenAt){
                                sx['left'] = column.frozenAt;
                            }
                            return (
                                <TableCell
                                    className={column.frozen? 'sticky' : ''}
                                    sx={sx}
                                    key={index}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth , color:"black", backgroundColor:'rgba(240, 242, 248, 1)'}}
                                    >
                                    {column.label}
                                </TableCell>   
                            )
                        })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .map((row,index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    let sx = {p:'1rem', borderBottom:'1px solid rgba(0, 0, 0, 0.1)'};
                                    if(column.frozenAt){
                                        sx['left'] = column.frozenAt;
                                    }
                                    return (
                                        <TableCell 
                                        className={column.frozen? 'sticky' : ''}
                                        sx={sx}
                                        key={column.id} 
                                        align={column.align}
                                        >
                                            {
                                                column.template
                                                ? column.template(row) 
                                                : value
                                            } 
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </>)
}

export default TableComponent;