import React, {useState, useEffect} from "react";
import { RadioGroup, Radio, FormControlLabel, FormHelperText, Box, Typography  } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const FormRadioGroup = ({index,surveyQuestions,setRadioField,controls,errorQuestions,question}) => {

    const [textField, setField] = useState(0);
    const [error, setError] =  useState(false);

    useEffect(() => {
        let field = [...surveyQuestions];
        field[index] = parseInt(textField);
        // console.log({surveyQuestions,index,textField,field})
        setRadioField(field);
    }, [textField])

    const checkError = () => {
      if(errorQuestions?.[index]){
        setError(true);
      } else {
        setError(false);
      }
    }

    useEffect(() => {
      checkError();
    }, [])

    useEffect(() => {
      checkError();
    }, [errorQuestions])

    return (<>
      <RadioGroup
          // row
          aria-labelledby={"Choice" + index}
          name="radio-buttons-group"
          value={textField}
          onChange={(event) => {setField(event.target.value)}}
          required
        >
          {controls.map((control, key) => {
              return (<FormControlLabel key={key} value={control.value} control={<Radio />} label={<Typography variant="defineSubtitle2">{control.label}</Typography>} />)
          })}
        </RadioGroup>
          {
            error && <FormHelperText sx={{color: 'rgba(212, 8, 8, 1)',mx:0,display: 'flex', alignItems: 'center'}}>
            <ErrorOutlineIcon fontSize='small' style={{ marginRight: '4px' }} />
            <Typography variant="caption" sx={{ color: 'rgba(212, 8, 8, 1)' }}>
            {/* Question is mandatory. You must provide answers before proceeding. */}
            This is a required question.
            </Typography>
         </FormHelperText>
          }
    </>)

}

export default FormRadioGroup;