import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 1)',
  backgroundColor: '#0F5FDC',
  '&:hover': {
    // backgroundColor: 'rgba(0, 0, 0, 1)',
  }
}));

export default function CustomizedButtons(props) {
  return (
      <ColorButton variant="outlined" onClick={props.onClick} sx={props.sx} type={props.type} disabled={props.disabled} >{props.label}</ColorButton>
  );
}