import axios from 'axios';
// import { getAppToken, getSelfToken } from "../_helper/secureToken"
// const BASE_URL = 'https://hcltechsurveyapi.eela.tech/api';
const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log({process,baseURL: BASE_URL})

class APIService {
  constructor(baseURL) {
    this.baseURL = BASE_URL || baseURL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      surveyList: 'survey',
      fetchSurvey: 'survey-slug',
      submitSurveyQuestions: 'survey-answer-slug',
      fetchResult: 'session-answer-slug',
      submitDetails: 'attendee',
      savePDF: 'report-store'
    };

  }

  async get(endpointKey, params = {}, searchParam = null) {
    // console.debug("API request", { params, searchParam })
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        // headers: { Authorization: 'Bearer ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        // headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  async postRequest(endpointKey, data = null, updateProgress = () => {}) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      // data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        // headers: { Authorization: 'Bearer ' + getSelfToken() }
        onUploadProgress: (event) => {
          const progress = Math.round((event.loaded / event.total) * 100);
          // console.log(`Upload progress: ${progress}%`,{event});
          if(progress == 100){
            // console.log("Process done");
            updateProgress();
          }
        },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        // headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        // headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
 
  async postAdopter(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // endpoint = endpoint + '?app=' + getAppToken();
      const response = await this.api.post(endpoint, data, {
        // headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

}



export default new APIService(BASE_URL);
