import React from "react";
import { Box,Table,TableBody,TableCell,TableContainer,TableRow,Typography } from "@mui/material";

const Legends = ({legendPosition = false}) => {

    return (<>

        <Box sx={legendPosition ? {position: 'absolute',top: '10rem', right: '0rem'} : {display: 'flex'}}>
            <Box sx={{background: 'rgba(209, 216, 228, 1)', padding: '8px 16px', borderRadius: '5px'}}></Box>
            <Box><Typography sx={{paddingLeft: '1rem',paddingRight: '1rem'}} variant="legend" color='#4F4F4F'>Nascent</Typography></Box>
            <Box sx={{background: 'rgba(162, 175, 200, 1)', padding: '8px 16px', borderRadius: '5px'}}></Box>
            <Box><Typography sx={{paddingLeft: '1rem',paddingRight: '1rem'}} variant="legend" color='#4F4F4F'>Focusing</Typography></Box>
            <Box sx={{background: 'rgba(100, 122, 163, 1)', padding: '8px 16px', borderRadius: '5px'}}></Box>
            <Box><Typography sx={{paddingLeft: '1rem',paddingRight: '1rem'}} variant="legend" color='#4F4F4F'>Emerging</Typography></Box>
            <Box sx={{background: 'rgba(253, 200, 112, 1)', padding: '8px 16px', borderRadius: '5px'}}></Box>
            <Box><Typography sx={{paddingLeft: '1rem',paddingRight: '1rem'}} variant="legend" color='#4F4F4F'>Establishing</Typography></Box>
            <Box sx={{background: 'rgba(252, 163, 17, 1)', padding: '8px 16px', borderRadius: '5px'}}></Box>
            <Box><Typography sx={{paddingLeft: '1rem',paddingRight: '1rem'}} variant="legend" color='#4F4F4F'>Leading</Typography></Box>
        </Box>
        {/* <TableContainer>
            <Table>
                <TableBody>
                    <TableRow tabIndex={-1} className="legend">
                        <TableCell sx={{background: '#FFFFFF !important'}}>
                            <Box sx={{background: 'rgba(140, 200, 250, 1)', padding: '6px 10px', borderRadius: '5px'}}></Box>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{paddingLeft: '1rem'}} variant="body2">Nascent</Typography>
                        </TableCell>
                        <TableCell>
                            <Box sx={{background: 'rgba(60, 145, 255, 1)', padding: '6px 10px', borderRadius: '5px'}}></Box>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{paddingLeft: '1rem'}} variant="body2">Focusing</Typography>
                        </TableCell>
                        <TableCell>
                            <Box sx={{background: 'rgba(140, 105, 240, 1)', padding: '6px 10px', borderRadius: '5px'}}></Box>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{paddingLeft: '1rem'}} variant="body2">Emerging</Typography>
                        </TableCell>
                        <TableCell>
                            <Box sx={{background: 'rgba(75, 195, 175, 1)', padding: '6px 10px', borderRadius: '5px'}}></Box>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{paddingLeft: '1rem'}} variant="body2">Establishing</Typography>
                        </TableCell>
                        <TableCell>
                            <Box sx={{background: 'rgba(0, 120, 115, 1)', padding: '6px 10px', borderRadius: '5px'}}></Box>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{paddingLeft: '1rem'}} variant="body2">Leading</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer> */}
    </>);
}

export default Legends;