import React, { useState, useRef, useEffect } from "react";
import { Grid, Box, Tabs, Tab, Button, IconButton, Typography } from "@mui/material";
import Item from "../styledComponents/Item";
// import { useNavigate } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReactToPDF from 'react-to-pdf';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import SpiderChart from "../component/SpiderChart";
import PolarAreaChart from "../component/PolarAreaChart";
import ApiService from "../utils/ApiService";
import TableComponent from "../features/TableComponent/TableComponent";
import reportTableColumns from "../dummy/reportTableColumns";
import reportTableData from "../dummy/reportTableData.json";
import reportTableData2 from "../dummy/reportTableData copy.json";
import Header from "../component/Header";
import Footer from "../component/Footer";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertDialog from "../component/AlertDialog";
import CustomizedButtons from "../styledComponents/CustomizedButtons";
import LoadingButton from '@mui/lab/LoadingButton';
import HclLogo from '../assets/images/HCLTechNewLogo.jpg';

const ReportPreview = ({ slug }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const ref = useRef();
    const tableRef = useRef();

    const headerHeight = useRef();
    const footerHeight = useRef();
    const [bodyHeight, setBodyHeight] = useState('90vh');
    const [contentHeight, setContentHeight] = useState('60vh');
    const pageHeading = useRef();
    const pageFooter = useRef();
    // const [tabIndex, setTabIndex] = React.useState("1");
    console.log({ location, navigate })
    const [chartData, setChartData] = useState(navigate.type !== 'POP' ? location.state?.chartData : null);
    const [surveyAnswers, setSurveyAnswers] = useState(location.state?.report);
    const [reportTableJson, setReportTableJson] = useState(slug == 'mdm-survey' ? reportTableData2 : reportTableData);
    const [tabContentHeight, setTabContentHeight] = useState('60vh');
    const [open, setOpen] = React.useState(false);
    const [showRestart, setShowRestart] = React.useState(true);
    const coOrdinateTableChart = slug == 'mdm-survey' ? 24 : 21;
    const [mailUrl, setMailUrl] = useState(location.state?.mailUrl);
    // const handleTabChange = (event, newValue) => {
    //     setTabIndex(newValue);
    //   };

    useEffect(() => {
        console.log({ location })
        if (!surveyAnswers || !chartData) {
            goToHome();
        }
        const handleBackButton = (event) => {
            console.log("Back Button Removed");
            if (window.location.pathname == "/report-preview") {
                // goToHome();
            }
        };
        window.addEventListener('popstate', handleBackButton);

        setContentHeight('calc(100vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px - ' + pageHeading?.current?.clientHeight + 'px - ' + pageFooter?.current?.clientHeight + 'px - 60px)');
        setTabContentHeight('calc(100vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px - ' + pageHeading?.current?.clientHeight + 'px - ' + pageFooter?.current?.clientHeight + 'px - 108px)');
        setBodyHeight('calc(100vh - ' + (headerHeight?.current?.clientHeight < 90 ? '5.875rem' : headerHeight?.current?.clientHeight + 'px') + ' - ' + footerHeight?.current?.clientHeight + 'px)')
        let json = slug == 'mdm-survey' ? reportTableData2 : reportTableData;
        setReportTableJson(json.map((v, i) => {
            return { ...v, background: index[chartData[i]] }
        }))

        // return () => {
        //     // Clean up the event listener when the component unmounts
        //     window.removeEventListener('popstate', handleBackButton);
        // };
    }, [])

    const index = ['', 'Nascent', 'Focusing', 'Emerging', 'Establishing', 'Leading'];

    useEffect(() => {
        let timer;

        const handleFunction = () => {
            console.log("Eureka")
            setTimeout(() => {
                generatePDF();
            }, 1100)
        };

        const cleanup = () => {
            clearTimeout(timer);
        };

        timer = setTimeout(handleFunction, 200);

        return cleanup;
    }, [reportTableJson]);

    const goToHome = () => {
        navigate(`/${slug}/questionnaire`);
    }

    const generatePDF = async () => {
        const element = ref.current;
        const canvas = await html2canvas(element, {
            allowTaint: true,
            scrollX: 0,
            scrollY: 0,
            width: 860,
        });
        // const canvasWidthInPixels = canvas.getBoundingClientRect().width;
        // console.log("")
        const data = canvas.toDataURL('image/jpeg', 0.8);
        const pdf = new jsPDF();
        const reader = new FileReader();
        reader.onload = () => {
            const imageData = reader.result;

            console.log(pdf.internal.pageSize.getWidth())
            // Add the image data to the PDF
            pdf.addImage(data, 'JPEG', 0, coOrdinateTableChart, pdf.internal.pageSize.getWidth(), canvas.getBoundingClientRect().height);
            // pdf.addImage(imageData, "JPEG", 0, 0, 86, 15);
            pdf.addImage(imageData, "JPEG", 2, 1, 49, 12);
            // pdf.line(0, 12, pdf.internal.pageSize.getWidth(), 12);

            // pdf.setFont(fonts.helvetica, 24);
            // pdf.text("MDM Modernization Maturity Model", 50, 20);
            pdf.setFontSize(24);
            pdf.text("MDM Modernization Maturity Model", 42, 24);

            // pdf.text("MDM Modernization", 70, 5);
            // pdf.text("Maturity Model", 70, 15);
            pdf.setFontSize(6);
            // pdf.text("© 2024, Mastech InfoTrellis, INC.", 140, 5);
            // pdf.text("Mastech InfoTrellis is the wholly-owned subsidiary of", 140, 9);
            // pdf.text("Mastech Digital. All rights reserved", 140, 13);
            pdf.text("© 2024, Mastech InfoTrellis, INC. Mastech InfoTrellis is the", 143, 5);
            pdf.text("wholly-owned subsidiary of Mastech Digital. All rights reserved", 140, 8);
            // pdf.text("", 140, 13);
            pdf.addPage();
            pdf.addImage(data, 'JPEG', 0, -(pdf.internal.pageSize.getHeight() - coOrdinateTableChart), pdf.internal.pageSize.getWidth(), canvas.getBoundingClientRect().height);
            if (slug == 'mdm-survey') {
                pdf.addPage();
                pdf.addImage(data, 'JPEG', 0, -(2 * pdf.internal.pageSize.getHeight() - coOrdinateTableChart), pdf.internal.pageSize.getWidth(), canvas.getBoundingClientRect().height);
            }
            let formData = new FormData();
            formData.append('sessionId', surveyAnswers?.session);
            let blob = new Blob([pdf.output('arraybuffer')], {type: 'application/pdf'});
            formData.append('file', blob );
            formData.append('sendReport', 1 );
            ApiService.postRequest('savePDF', formData, () => {
                setShowRestart(false);
            })
            .then((res) => {
                // console.log({res});
                setShowRestart(false);
            })
            .catch((exc) => {
                console.log({exc});
                // setShowRestart(false);
            })
            // pdf.save('report.pdf');
        };
        // HCLTechNewLogo
        const file = new File([await fetch('/media/MastechLogo.png').then(response => response.blob())], 'logo.jpg');
        // Read the image file
        reader.readAsDataURL(file);
    };

    const handleScheduleConsultant = () => {
        window.open(mailUrl);
        console.log('a1', mailUrl)
    };


    return (
        <Box sx={{ height: '100vh', width: '100%' }} className="background-body">
            <Header />

            <Box sx={{}}>
                {/* <Box sx={{}}> */}
                <Box sx={{ height: 0, overflow: "hidden" }}>
                    {/* <Box sx={{width: '100%', position: 'relative'}}> */}
                    <Box sx={{ maxHeight: contentHeight ?? '60vh', overflow: 'auto' }}>
                        <Box ref={ref} sx={{ padding: '1rem', width: (slug == 'mdm-survey' ? '830px' : '800px'), overflow: 'auto', background: 'transparent', position: 'relative' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', '& > div': { position: 'absolute', bottom: '2rem' } }}>
                                {chartData && <PolarAreaChart slug={slug} fontSizeChart={14} chartData={chartData} />}
                            </Box>
                            <Box ref={tableRef} id="table" sx={{ position: 'relative', bottom: '7px' }}>
                                <TableComponent columns={reportTableColumns} data={reportTableJson} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ height: 'calc(100vh - 36px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '40rem', textAlign: 'center' }}>
                    <Box>
                        <Typography variant="h5" sx={{ paddingBottom: '1rem' }}>
                            Thank you for taking time to complete the survey. We look forward to continuing the conversation.
                        </Typography>
                        <Box>
                            <Button variant="contained" sx={{ background: 'rgba(252, 163, 17, 1)', color: 'black', mr: 2 }} onClick={handleScheduleConsultant}>
                                Schedule Consultation
                            </Button>
                            <CustomizedButtons sx={{ '&.Mui-disabled': { color: '#8291A0' }, background: 'rgba(252, 163, 17, 1)', color: 'black', border: 'none' }} disabled={showRestart} onClick={goToHome} label={showRestart ? "Generating Report..." : "Restart Survey"} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
}

export default ReportPreview;