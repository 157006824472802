import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({open, setOpen, goToHome}) {

  const handleClose = () => {
    setOpen(false);
  };

  const restart = () => {
    setOpen(false);
    goToHome();
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to restart the survey? Please be aware that this action will result in the loss of all the progress you have made so far.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{textTransform: "none"}} onClick={restart}>Yes, Restart</Button>
          <Button sx={{textTransform: "none"}} onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
