import React, {useState,useEffect} from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Chart, ArcElement, RadialLinearScale, Legend } from 'chart.js';
import Legends from './Legends';
import { useMediaQuery } from 'react-responsive';
import { Box } from "@mui/material";

Chart.register(ArcElement, RadialLinearScale, Legend);

const PolarAreaChart = ({chartData,fontSizeChart = 12, legendPosition = false, slug}) => {

  const isMobilePortrait = useMediaQuery({ query:'(max-width: 576px) and (orientation: portrait)'});

  const [fontSize, setFortSize] = useState(fontSizeChart);

  // useEffect(() => {
  //   setFortSize(isMobilePortrait ? 10 : 18);
  // }, [isMobilePortrait])

  const backgroundColor = {
    0: '#000000',
    1: 'rgba(209, 216, 228, 1)',
    2: 'rgba(162, 175, 200, 1)',
    3: 'rgba(100, 122, 163, 1)',
    4: 'rgba(253, 200, 112, 1)',
    5: 'rgba(252, 163, 17, 1)',
    6: 'rgba(225, 180, 17, 1)',
  };

  console.log({chartData, result:  chartData.map(v => backgroundColor[v]), slug })

  const labels = (slug == 'mdm-survey') ? ['Architecture', 'Use Case Implementation', 'Flexibility', 'Smart Entity Resolution', 'Data Governance', 'Data Quality', 'Operational Monitoring'] : ['Architecture', 'Flexibility', 'Smart Entity Resolution', 'Data Quality', 'Data Governance', 'User Experience'] ;
  // labels: ['Cloud-Native and SaaS Architecture', 'Data Integration and Modeling Flexibility', 'Smart Entity Resolution with Machine Learning', 'Real-Time Data Quality and Operational MDM', 'Robust Data Governance and Security', 'Intuitive User Experience and Collaboration']
  const data = {
    labels: labels,
    datasets: [
      {
        data: [...chartData],
        backgroundColor: chartData.map(v => backgroundColor[v]),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    title: 'Polar Area Chart',
    plugins: {
      legend: {
        display: false,
        position: 'bottom'
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
        r: {
          ticks: {
            stepSize: 1,
            callback: function(value) {if (value % 1 === 0) {return value;}}
          },
          pointLabels: {
            display: true,
            centerPointLabels: true,
            font: {
              size: fontSize,
              color: '#000000',
            },
            // fontColor: '#000000',
          },
          min: 0,
          max: 5,
        }
    },
  };

  // minWidth={200} maxWidth={500}
  return (
    <>
      {legendPosition ? <Box sx={{display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center'}} >
        <PolarArea data={data} options={options} />
        <Legends legendPosition={legendPosition} />
      </Box> : <>
        <PolarArea data={data} options={options} />
        <Legends />
      </>}
    </>
  );
};

export default PolarAreaChart;
