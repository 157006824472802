import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Box from '@mui/material/Box';

const Layout = ({ showXpoLabel = true,children }) => {
  return (
    <div>
      <Header showXpoLabel={showXpoLabel} />
      <Box component="main" sx={{ flexGrow: 1, background: '#EEF6FF', minHeight: '100vh',
    //   pt: { xs: 'none', sm: 'block' }
       }}>
                     {children}</Box>
      <Footer />
    </div>
  );
};

export default Layout;
