import React, {useState, useEffect} from "react";
import { Box, Button  } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Header from "../component/Header";
import Footer from "../component/Footer";
import Typography from '@mui/material/Typography';
import CustomizedButtons from "../styledComponents/CustomizedButtons";
// import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
// import ApiService from "../utils/ApiService";
import { useLocation } from 'react-router-dom';

const RestartSurvey = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [runningSurvey, setRunningSurvey] = useState(location.state?.restart);

    const goToHome = () => {
        navigate("/home");
    }

    useEffect(() => {
        if(!runningSurvey){
            goToHome();
        }
    //     document.title = "HCLTech Survey";
    }, [])

    return(<Box sx={{height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className="background-body">
        <Header />
        <Box sx={{maxWidth: '40rem'}}>
            <Box>
                <Typography variant="h5" sx={{paddingBottom: '0.75rem'}}>
                    Thank you for sharing your valuable feedback with us! Your input is greatly appreciated.
                </Typography>
                <CustomizedButtons onClick={goToHome} label={"Restart Survey"} />
            </Box>
            {/* <ImageOutlinedIcon sx={{width: '20rem', height: '20rem'}} />  */}
        </Box>
        <Footer />
    </Box>);
}

export default RestartSurvey;