import React, {useState, useRef, useEffect} from "react";
import { Grid, Box, FormControl, FormLabel, Typography, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Header from "../component/Header";
import Footer from "../component/Footer";
import CustomizedButtons from "../styledComponents/CustomizedButtons";
// import Survey from "../component/survey";
import FormRadioGroup from "../component/RadioGroup";
import ApiService from "../utils/ApiService";
import { useLocation } from 'react-router-dom';
import AlertDialog from "../component/AlertDialog";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

const Questionnair = ({slug}) => {

    const location = useLocation();
    // const [questions, setQuestions] = useState(dummyQuestions[0]);
    const [surveyQuestions, setSurveyQuestions] = useState(location.state?.questions?.map(v => 0) ?? []);
    const [mailUrl, setMailUrl] = useState("");

    const [errorQuestions,setErrorQuestions] = useState([]);
    // const [feedPostData, setFeedPostData] = useState(location.state.feedPostData);
    const [questions, setQuestions] = useState(location.state?.questions ?? []);
    const [showLoader, setShowLoader] = useState(false);

    const headerHeight = useRef();
    const footerHeight = useRef();
    const [bodyHeight, setBodyHeight] = useState('90vh');
    const [contentHeight, setContentHeight] = useState('60vh');
    const pageHeading = useRef();
    const pageFooter = useRef();
    const [contentTop,setContentTop] = useState('6rem');

    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const [openSnakebar, setOpenSnakebar] = React.useState(false);
    const [surveyMessage, setSurveyMessage] = useState("Survey has been ended");

    const handleClick = () => {
        setOpenSnakebar(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnakebar(false);
    };
    
    useEffect(() => {

        setShowLoader(true);
        ApiService.get('fetchSurvey', {}, slug)
        .then((res) => {
            let data = res.data.data?.questionGroup?.reduce((prev,curr) => {
                prev = [...prev,...curr.questions];
                return prev;
            }, []);
            console.log({res,data});
            if(!data){
                setSurveyMessage("Survey has been ended");
                handleClick();
                setTimeout(() => {
                    goToHome();
                }, 3000)
            } if(res.data.data.activeStatus == 0){
                setSurveyMessage("Survey has been ended");
                handleClick();
                setTimeout(() => {
                    goToHome();
                }, 3000)
            } else {
                setQuestions(data);
                setSurveyQuestions(data.map(v => 0))
                setMailUrl(res.data.data.mailUrl?.[0]?.url);
                console.log('a1',res.data.data.mailUrl?.[0]?.url,res)
            }
            setShowLoader(false);
            // navigate(`/${slug}/questionnaire`,{state: {questions: data}});
        }).catch((exc) => {
            console.log({exc})
            setSurveyMessage("Survey does not exist");
            handleClick();
            setTimeout(() => {
                goToHome();
            }, 2000)
        })
       
        setContentTop(headerHeight?.current?.clientHeight < 90 ? '3.875rem' : headerHeight?.current?.clientHeight);
        setContentHeight('calc(100vh - '+(headerHeight?.current?.clientHeight < 90 ? '2.675rem' : headerHeight?.current?.clientHeight + 'px')+' - '+ footerHeight?.current?.clientHeight + 'px - ' + pageHeading?.current?.clientHeight + 'px - ' + pageFooter?.current?.clientHeight + 'px)');
        setBodyHeight('calc(100vh - '+(headerHeight?.current?.clientHeight < 90 ? '3.875rem' : headerHeight?.current?.clientHeight + 'px')+' - '+ footerHeight?.current?.clientHeight + 'px)');
        // document.title = "HCLTech Survey";
        console.log({slug})
    }, [])

    const options = [
        {
            label: "Not at all",
            value: 1
        },{
            label: "Rarely",
            value: 2
        },{
            label: "Moderately",
            value: 3
        },{
            label: "Mostly",
            value: 4
        },{
            label: "Continuously/Always",
            value: 5
        }
    ];
    
    const goToFeedBack = (event) => {
        event.preventDefault();
        console.log("Hit vs Jiren",{surveyQuestions, map: surveyQuestions.map(v => {
            console.log("Hit vs Jiren", {v, cond: v ? false : true});
            return v ? false : true;
        })});
        if(surveyQuestions.every(v => v)){
            let formData =new FormData();
            // formData.append('surveyId', 1);
            formData.append('slug', slug);
            formData.append('questionType', 'Opinion-State');
            surveyQuestions.map((v,i) => formData.append('answer[' + i + ']', v));
            questions.map((v,i) => formData.append('questionId[' + i + ']', v.id));
            questions.map((v,i) => formData.append('questionGroupId[' + i + ']', parseInt(v.groupId)));
            ApiService.post('submitSurveyQuestions',formData)
            .then((res) => {
                console.log({res})
                console.log({mailUrl})
                // navigate("/report", {state: {report: res.data}});
                navigate(`/${slug}/feedback`, {state: {report: res.data, mailUrl: mailUrl}});
            })
            .catch((exc) => {
                console.log({exc});
            })
        } else {
            setErrorQuestions(surveyQuestions.map(v => v ? false : true));
        }
    }

    const restartDialogOpen = () => {
        setOpen(true);
    };

    const goToHome = () => {
        // navigate("/");
        window.location.reload()
    }

    return(<Box sx={{height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative',textAlign: 'left'}} className="background-body">
        <Header headerHeight={headerHeight} />
        <Box sx={{position: 'absolute', top: contentTop ?? '6rem', height: bodyHeight, px: '1rem', width: '100%'}}>
            <Box ref={pageHeading} sx={{pt:{xs:1,sm:2,md:3}}}>
                <Typography variant="subtitle1" sx={{paddingBottom: '2rem'}}>
                    Questionnaire
                </Typography>
            </Box>

            <form onSubmit={goToFeedBack}>
                <Grid container spacing={2} sx={{maxHeight: contentHeight ?? '60vh',height: '100vh', overflow: 'auto', '& .MuiGrid-item:first-of-type': {'paddingTop': 0}}}>
                    {showLoader ? (<Box sx={{minHeight: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                        <CircularProgress />
                    </Box>) : (<>
                        {questions?.map((question, index) => {
                            return (<Grid key={index} item xs={12}>
                                <Box>
                                    <Box sx={{background: 'rgba(244, 246, 250, 1)', width: '100%', borderRadius: '5px',fontFamily: 'Raleway-Regular !important'}}>
                                        <FormControl sx={{padding: '1rem',fontFamily: 'Raleway-Regular !important'}}>
                                            <FormLabel id={"Choice" + index}><Typography sx={{fontFamily: 'Raleway-Regular !important'}} variant="defineSubtitle2" fontWeight={600}>Q{index + 1}. {question.question}</Typography><span style={{color: 'red'}}>*</span></FormLabel>
                                            <FormRadioGroup index={index} surveyQuestions={surveyQuestions} setRadioField={setSurveyQuestions} controls={question.optionChoice.map((v,i) => { return {label: v.optionChoiceName, value: (v.value ?? (i + 1))} })} errorQuestions={errorQuestions}  />
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Grid>)
                        })}
                    </>)}
                </Grid>
                <Box sx={{width: '100%'}} ref={pageFooter}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', pb: '1rem', pt:'1rem',marginbottom: '2rem'}}>
                        <Button variant="outlined" onClick={restartDialogOpen} >Restart Survey</Button>
                        <CustomizedButtons sx={{background: 'rgba(252, 163, 17, 1)',border:'none',color:'black'}} label={"Next"} onClick={(e) => goToFeedBack(e)} />
                    </Box>
                </Box>
            </form>
            
            <AlertDialog open={open} setOpen={setOpen} goToHome={goToHome} />
            <Snackbar
                open={openSnakebar}
                autoHideDuration={6000}
                onClose={handleClose}
                message={surveyMessage}
            />
        </Box>
         <Footer footerHeight={footerHeight} />
    </Box>);
}

export default Questionnair;