import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CheckboxField({setValue,value,label, required = false}) {
  const [checked, setChecked] = React.useState(value);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    setValue(checked);
  }, [checked])

  return (
    <FormGroup>
         <FormControlLabel control={
         <Checkbox size='medium'
            checked={checked}
            sx={{pt:0, alignSelf: 'start'}}
            onChange={handleChange}
            inputProps={{ 'aria-label': label }}
          />
          } label={<Typography pb={1} variant='defineCaption' color='rgba(0, 0, 0, 0.87)'>{label} </Typography>}
          required={required} />
    </FormGroup>
    
  );
}