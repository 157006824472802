import React from "react";
import { Box } from "@mui/material";

const Footer = ({footerHeight,type='fixed'}) => {
    let sx = {width: '100%', background: "rgba(100, 122, 163, 1)"};
    if(type == 'fixed'){
        sx={...sx,position: 'fixed', bottom: 0}
    }
    return (<Box sx={sx} ref={footerHeight}>
        <Box sx={{display: 'flex',justifyContent: 'center', alignItems: 'center',padding: "8px 16px"}}>
            <Box sx={{fontSize: 'clamp(.75rem, .75rem + 0vw, .75rem)',color:'rgba(255, 255, 255, 1)'}}>
            © 2024, Mastech InfoTrellis, INC. Mastech InfoTrellis is the wholly-owned subsidiary of Mastech Digital. All rights reserved
            </Box>
        </Box>
    </Box>)
}

export default Footer;