import React from "react";
import { Box } from "@mui/material";
import GartnerImage from '../assets/images/gartner-xpo-news.jpg';
import HclLogo from '../assets/images/MastechLogo.png';
import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({headerHeight, showXpoLabel}) => {

    return (
    // <Box sx={{position: 'fixed', top: 0, width: '100%', background: "rgba(255, 255, 255, 1)", zIndex:"1"}} ref={headerHeight}>
    //     <Box sx={{display: 'flex',justifyContent: 'space-between',padding: "8px 16px", alignItems: 'center'}}>
    //         <Box sx={{height: {xs: '27.6px', md: '46px'}}}>
    //             <img src={HclLogo} alt="HCLTech" style={{height: '100%'}} />
    //         </Box>
    //         <Box sx={{height: {xs: '44.4px', md: '74px'}}}>
    //             <img src={GartnerImage} alt="Gartner XPO News" style={{height: '100%'}} />
    //         </Box>
    //     </Box>
    // </Box>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{boxShadow:'none',background:'#fff'}} ref={headerHeight}>
        <Toolbar sx={{justifyContent: 'space-between'}}>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Box sx={{display: 'flex',justifyContent: 'space-between',padding: "8px 16px", alignItems: 'center'}}> */}
          <Box sx={{height: {xs: '27.6px', md: '46px'}}}>
                <img src={HclLogo} alt="HCLTech" style={{height: '100%'}} />
             </Box>
             {/* <Box sx={{height: {xs: '44.4px', md: '74px'}}}>
               {showXpoLabel && <img src={GartnerImage} alt="Gartner XPO News" style={{height: '100%'}} />}
            </Box> */}
            {/* </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
    )
}

export default Header;