import React, {useState, useEffect} from "react";
import { TextField } from "@mui/material";

const FormTextField = (field) => {

    const [textField, setField] = useState(field.value);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\.com$/
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
    
        // console.log("Cond", !field.type && field.required && textField == '',!field.type , field.required , value == '', {field})
        if ( field.type == 'email' && !emailRegex.test(value)) {
          setError(true);
          field.setNeedError(true);
          setErrorMessage('Email should be in right format. e.g: hello@gmail.com');
        } else if(!field.type && field.required && value == ''){
            setError(true);
            setErrorMessage('Field is required');
        } else {
            field?.setNeedError?.(false);
            setError(false);
            setErrorMessage('');
        }
        setField(value);
    };

    useEffect(() => {
        // if(!error){
            field.setValue(textField)
        // }
    }, [textField])

    return (<>
        <TextField  size="small" label={field.label} value={textField} onChange={handleInputChange} required={field.required} sx={{
            '& label': {
            fontSize: '16px', 
        },
        '&.MuiFormControl-root': {
            borderRadius: '4px !important',
        },
        ...field.sx}} variant={field.variant}
        //    placeholder={field.label}
        type={field.type ?? "text"} 
        pattern={field.type ? emailRegex : '.*'} 
        error={error}
        helperText={errorMessage ? errorMessage : ''}
        />
        {/* {field.type && error && (<>Please Enter Valid Email</>)} */}
    </>)

}

export default FormTextField;